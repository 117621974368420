import React from 'react';
import Container from 'react-bootstrap/Container'

const OlympusOMDEM1X = () => {
    return (
        <Container>
            <h1>Olympus OM-D E-M1X</h1>
        </Container>
    )

}

export default OlympusOMDEM1X;
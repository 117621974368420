import React from 'react';
import Container from 'react-bootstrap/Container';

const Booking = () => {
  return (
    <>
    </>
  )
}

export default Booking;

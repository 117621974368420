import React from 'react';
import Container from 'react-bootstrap/Container'

const LeicaX2 = () => {
    return (
        <Container>
            <h1>Leica X2</h1>
        </Container>
    )

}

export default LeicaX2;
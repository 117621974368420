import React from 'react';
import Container from 'react-bootstrap/Container'

const LeicaM3 = () => {
    return (
        <Container>
            <h1>Leica M3</h1>
        </Container>
    )

}

export default LeicaM3;
import React from 'react';
import Container from 'react-bootstrap/Container'

const RicohGRIII = () => {
    return (
        <Container>
            <h1>Ricoh GR III</h1>
        </Container>
    )

}

export default RicohGRIII;

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Mis = () => {
  return (
    <Container>
      <Row>
        <Col>
          title page thing goes here
        </Col>
        <Col>
          Intense taste, sound, and visuals coalesce into something special. Mis is a poetic and visual journey through the abstract aspects of dining that have been drawing humans to hospitality since the beginning of time.
        </Col>
      </Row>
    </Container>
  )
}

export default Mis;

import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const APoemOfLima = () => {
  return (
    <Container>
      <Row>
        <Col>
          photos go here
        </Col>
        <Col>
          Images that rhyme.  
        </Col>
      </Row>
    </Container>
  )
}

export default APoemOfLima

import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const MidnightAtMain = () => {
  return (
    <>
      <Container>
        <Row>
          <Col>
            photo goes here
          </Col>
          <Col>
            A short poem that congealed from nights drunkly spent on a small stretch of downtown road in Houston, Texas
          </Col>
        </Row>
      </Container>
    </>
  )

}

export default MidnightAtMain;
